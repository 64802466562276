import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar/Sidebar'

const NaftaIrDujos = () => (
  <Layout>
      <Helmet>
          <title>Reagentai Naftos ir Dujų Gavybai</title>
      </Helmet>
      <Header         
        styleName='header header-oil'
        title={<span className="text-header-title">Naftos ir dujų gavyba</span>}/>
      <div className="columns">
        <div className="column content">
          <div className="title-small-nomargintop title-small content-title">Reagentai naftos ir dujų pramonei</div>
          <div className="content-text">Šiuolaikinės IN-ECO™ technologijos, skirtos naftos bei dujų pramonei, sudaro galimybes optimaliai naudoti resursus, užtikrinti didesnį procesų ekologiškumą, prailginti įrengimų funkcionavimą, sumažinti eksploatacijos kaštus.<br/>
            <br/>
            Mes siūlome platų produktų bei technologinių sprendimų pasirinkimą klodinių vandenų apdirbimui, naftos pramonės ir gavybos įrenginių apsaugai, naftos paruošimui bei transportavimui:</div>
          <ul>
              <li className="content-text">Korozijos inhibitoriai pirminiam naftos perdirbimui</li>
              <li className="content-text">Antiputokšliai ir kiti produktai naftos, dujų ir vandens frakcionavimui</li>
              <li className="content-text">Agresyviųjų dujų (CO<sub>2</sub> ir H<sub>2</sub>S surišėjai)</li>
              <li className="content-text">Baktericidai</li>
              <li className="content-text">Nuovirų inhibitoriai</li>
              <li className="content-text">Korozijos inhibitoriai neapdirbtos naftos transportavimui</li>
              <li className="content-text">Deemulgatoriai</li>
          </ul>
        </div>
        <Sidebar
        analysis='Anketos'
        boiler='Boileriams'
        cooling='Aušinimui'
        ro='Osmosui'
        broshure='IN-ECO brošiura'
        certificates='Sertifikatai'
        />
      </div>
    </Layout>
)

export default NaftaIrDujos